/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import { FloorballColor, StockType } from "../ressources/types/floorball.types";
import { equalStock } from "../utils/equalsHelper";

const initialConfigurationState = {
  leftColor: FloorballColor.EMPTY_RED,
  rightColor: FloorballColor.EMPTY_RED,
  error: null,
};

export interface ConfigurationAction {
  type: string;
  leftColor?: FloorballColor;
  rightColor?: FloorballColor;
  error?: Error;
}

function configuration(
  state = initialConfigurationState,
  action: ConfigurationAction
) {
  switch (action.type) {
    case "SET_LEFTCOLOR_SUCCEEDED":
      return { ...state, leftColor: action.leftColor };
    case "SET_RIGHTCOLOR_SUCCEEDED":
      return { ...state, rightColor: action.rightColor };
    case "RESET_CONFIGURATION":
      return initialConfigurationState;
    case "CONFIGURATION_FAILED":
      return { ...state, error: action.error };
    default:
      return state;
  }
}

const initialStocksState = {
  items: null,
  isLoading: false,
  error: null,
};

export interface StocksAction {
  type: string;
  items?: StockType[];
  isLoading?: boolean;
  error?: Error;
}

function stocks(state = initialStocksState, action: StocksAction) {
  switch (action.type) {
    case "FETCH_STOCK_STARTED":
      if (!state.items) {
        return { ...state, isLoading: true };
      }
      return state;
    case "FETCH_STOCK_SUCCEEDED":
      if (action.items && !equalStock(state.items, action.items)) {
        return { ...state, items: action.items, isLoading: false };
      }
      return { ...state, isLoading: false };
    case "FETCH_STOCK_FAILED":
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
}

const reducers = combineReducers({
  configuration,
  stocks,
});

export function getConfigurationLeftColor(state: any): FloorballColor {
  return state.configuration.leftColor;
}

export function getConfigurationRightColor(state: any): FloorballColor {
  return state.configuration.rightColor;
}

export function getStocks(state: any): StockType[] {
  return state.stocks.items;
}

export function getStocksIsLoading(state: any): boolean {
  return state.stocks.isLoading;
}

export default reducers;

export { configuration as testConfiguration, stocks as testStocks };
