import React, { useEffect, useState } from 'react';
import { getFloorballColorName } from '../../../utils/colorHelper';
import './color.scss';

function Color(props: { color: string; setColor: (color: string) => void; disabled: boolean; active: boolean }) {
    const [disabled, setDisabled] = useState<string>('');
    const [active, setActive] = useState<string>('');

    useEffect(() => {
        setDisabled(props.disabled ? 'disabled' : '');
        setActive(props.active ? 'active' : '');
    }, [props]);

    function setColor() {
        if (!props.disabled) {
            props.setColor(getFloorballColorName(props.color));
        }
    }

    return (
        <div className={`colorwrapper ${getFloorballColorName(props.color)} ${disabled} ${active}`}>
            <div className='floorball-color' style={{ backgroundColor: `${props.color}` }} onClick={setColor}>
                {' '}
            </div>
        </div>
    );
}

export default Color;
