import React from "react";

import Configurator from "./pages/Configurator/Configurator";

function App() {
  return (
    <section id="content">
      <Configurator></Configurator>
    </section>
  );
}

export default App;
