import { AxiosResponse } from "axios";

import axiosInstance from "../utils/axiosInstance";
import { StocksResponse } from "../ressources/types/floorball.types";

function getJson(endpoint: string): Promise<any> {
  return axiosInstance.get(`${endpoint}`).then((res) => {
    if (res.statusText === "refreshed") {
      return axiosInstance.post(`${endpoint}`);
    }
    return res;
  });
}

export function getStocks(): Promise<AxiosResponse<StocksResponse>> {
  return getJson("/stocks");
}
