import { StockType } from "../ressources/types/floorball.types";


export function equalStock(stock1: StockType[] | null, stock2: StockType[] | null): boolean {
    if(stock1 && stock2) {
        stock1.sort((a: StockType, b: StockType) => b.color.name.localeCompare(a.color.name));
        stock2.sort((a: StockType, b: StockType) => b.color.name.localeCompare(a.color.name));
        if(!equalLength(stock1, stock2)) {
            return false;
        }
        for(let i = 0; i < stock1.length; i++ ) {
            if(stock1[i].color.name !== stock2[i].color.name || stock1[i].amount !== stock2[i].amount) {
                return false;
            }
        }
        return true;
    }
    return stock1 === stock2;
}

export function equalLength(obj1: object[] | null, obj2: object[] | null): boolean {
    return obj1?.length === obj2?.length;
}