import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import * as api from "../services/api";
import constants from "../ressources/constants";

import {
  Configuration,
  FloorballColor,
  StocksResponse,
} from "../ressources/types/floorball.types";

export function setConfigurationLeftColor(leftColor: FloorballColor) {
  return (dispatch: any) => {
    if (leftColor) {
      dispatch({ type: "SET_LEFTCOLOR_SUCCEEDED", leftColor });
    } else {
      dispatch({
        type: "CONFIGURATION_FAILED",
        error: new Error("invalid color"),
      });
    }
  };
}

export function setConfigurationRightColor(rightColor: FloorballColor) {
  return (dispatch: any) => {
    if (rightColor) {
      dispatch({ type: "SET_RIGHTCOLOR_SUCCEEDED", rightColor });
    } else {
      dispatch({
        type: "CONFIGURATION_FAILED",
        error: new Error("invalid color"),
      });
    }
  };
}

export function resetConfiguration() {
  return (dispatch: any) => {
    dispatch({ type: "RESET_CONFIGURATION" });
  };
}

export function fetchStocks(service: any = api) {
  return (dispatch: any) => {
    dispatch({ type: "FETCH_STOCK_STARTED" });
    return service
      .getStocks()
      .then((result: AxiosResponse<StocksResponse>) => {
        dispatch({ type: "FETCH_STOCK_SUCCEEDED", items: result.data.stocks });
      })
      .catch((error: Error) => {
        dispatch({ type: "FETCH_STOCKS_FAILED", error });
      });
  };
}
