import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FloorballColorHex, StockType } from '../../../ressources/types/floorball.types';
import Color from '../Color/Color';
import { getConfigurationLeftColor, getConfigurationRightColor, getStocks, getStocksIsLoading } from '../../../redux/reducers';
import './colorPalette.scss';
import { getFloorballColorName } from '../../../utils/colorHelper';

function ColorPalette({ props, leftColor, rightColor, stocks, isLoading }: PropsFromRedux) {
    const {t} = useTranslation();
    const [colors, setColors] = useState<any[]>([]);
    const [outOfStock, setOutOfStock] = useState<boolean>(false);

    useEffect(() => {
        const items: { [index: string]: { amount: number } } = {};
        if (stocks) {
            setColors([]);
            stocks.forEach((item: StockType) => {
                items[item.color.hexCode] = { amount: item.amount };
            });
            const availableStock = stocks.filter((item: StockType) => {
                return item.amount > 1;
            });
            setOutOfStock(Object.keys(availableStock).length === 0);
        }
        for (const color of Object.values(FloorballColorHex)) {
            const disabled = props.enableAll ? false : items[color]?.amount < 2;
            const active = getFloorballColorName(color) === props.active;
            setColors((colors) => [
                ...colors,
                <Color key={color} color={color} setColor={props.action} disabled={disabled || outOfStock} active={active} />,
            ]);
        }
    }, [leftColor, rightColor, stocks]);

    if (isLoading) {
        return (
            <div className='colorpalette loading'>
                <Loader active inline='centered' size='huge' />
            </div>
        );
    }

    return (
        <div className='colorpalette'>
            {colors}
            {outOfStock && <div className='out-of-stock'>{t("pages-configurator-outOfStock")}</div>}
        </div>
    );
}

const mapStateToProps = (state: any, props: { enableAll?: boolean; active: string; action: any }) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
    stocks: getStocks(state),
    isLoading: getStocksIsLoading(state),
    props,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ColorPalette);
