import React, { useEffect, useState, lazy, Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  fetchStocks,
  resetConfiguration,
  setConfigurationLeftColor,
  setConfigurationRightColor,
} from "../../redux/actions";
import {
  getConfigurationLeftColor,
  getConfigurationRightColor,
  getStocks,
} from "../../redux/reducers";
import { StockType } from "../../ressources/types/floorball.types";
import Floorball from "../Floorball/Floorball";
import ColorPalette from "./ColorPalette/ColorPalette";
import "./colorpicker.scss";
import ColorPickerModal from "./ColorPickerModal/ColorPickerModal";

const Floorball3D = lazy(() => import("../Floorball3D/Floorball3D"));

function ColorPicker({
  leftColor,
  rightColor,
  stocks,
  fetchStocks,
  setConfigurationLeftColor,
  setConfigurationRightColor,
  resetConfiguration,
}: PropsFromRedux) {
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [is3D, setIs3D] = useState<boolean>(false);

  useEffect(() => {
    handleResize();
    fetchStocks();
    const polling = setInterval(() => {
      fetchStocks();
    }, 5000);

    if (stocks) {
      stocks.forEach((stock: StockType) => {
        if (
          stock.amount <= 1 &&
          (stock.color.name === leftColor || stock.color.name === rightColor)
        ) {
          resetConfiguration();
        }
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(polling);
    };
  }, [mobileView, setMobileView, leftColor, rightColor, stocks]);

  function handleResize() {
    if (window.innerWidth <= 900) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }

  function floorbalLWrapper() {
    return (
      <div
        id="floorballwrapper"
        style={{ backgroundImage: "url('/shadow.png')" }}
      >
        <Suspense fallback={null}>
          <Floorball3D leftColor={leftColor} rightColor={rightColor} />
        </Suspense>
      </div>
    );
  }

  if (mobileView) {
    return (
      <div>
        <div id="colorpicker" className="mobile">
          {floorbalLWrapper()}
          <div id="mobileColors">
            <ColorPickerModal
              action={setConfigurationLeftColor}
              active={leftColor}
              initialColor={leftColor}
            />
            <ColorPickerModal
              action={setConfigurationRightColor}
              active={rightColor}
              initialColor={rightColor}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div id="colorpicker">
        <div id="firstcolor">
          <ColorPalette action={setConfigurationLeftColor} active={leftColor} />
        </div>
        {floorbalLWrapper()}
        <div id="secondcolor">
          <ColorPalette
            action={setConfigurationRightColor}
            active={rightColor}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  leftColor: getConfigurationLeftColor(state),
  rightColor: getConfigurationRightColor(state),
  stocks: getStocks(state),
});

const mapDispatchToProps = {
  setConfigurationLeftColor,
  setConfigurationRightColor,
  fetchStocks,
  resetConfiguration,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ColorPicker);
