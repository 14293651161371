import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FloorballColor } from '../../../ressources/types/floorball.types';
import getFloorballColorHex from '../../../utils/colorHelper';
import ColorPalette from '../ColorPalette/ColorPalette';
import './colorPickerModal.scss';

function ColorPickerModal(props: { action: any; active: string; enableAll?: boolean; initialColor?: FloorballColor }) {
    const {t} = useTranslation();
    const backgroundUrl = `url('/img/floorball/color-palette.png')`;
    const [open, setOpen] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState<string>('');
    const [background, setBackground] = useState<Object>({ backgroundImage: backgroundUrl });

    useEffect(() => {
        if (props.initialColor && props.initialColor !== FloorballColor.EMPTY_RED) {
            setSelectedColor(props.initialColor);
        }
    }, []);

    useEffect(() => {
        if (selectedColor) {
            setBackground({ backgroundColor: getFloorballColorHex(selectedColor) });
        }
    }, [selectedColor]);

    function action(color: FloorballColor) {
        setSelectedColor(color);
        setOpen(false);
        props.action(color);
    }

    function handleCancel() {
        setOpen(false);
        setSelectedColor('');
        setBackground({ backgroundImage: backgroundUrl });
        props.action(FloorballColor.EMPTY_RED);
    }

    return (
        <div className='colorwrapper modal'>
            <Modal basic onClose={() => setOpen(false)} open={open} className='colorModal'>
                <Modal.Header>{t("colorPicker-mobileTitle")}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ColorPalette action={action} active={props.active} enableAll={props.enableAll || false} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={() => handleCancel()}>
                        {t("button-reset")}
                    </Button>
                    <Button color='red' onClick={() => setOpen(false)}>
                        {t("button-cancel")}
                    </Button>
                </Modal.Actions>
            </Modal>
            <div className='floorball-color' style={background} onClick={() => setOpen(true)}></div>
        </div>
    );
}

export default ColorPickerModal;
