import { FloorballColor, FloorballColorHex } from "../ressources/types/floorball.types";

export function getFloorballColorHex(color : string) : FloorballColorHex {
    switch(color) {
        case FloorballColor.BLACK:
            return FloorballColorHex.BLACK;
        case FloorballColor.BLACKBERRY:
            return FloorballColorHex.BLACKBERRY;
        case FloorballColor.BLUE:
            return FloorballColorHex.BLUE;
        case FloorballColor.GRAY:
            return FloorballColorHex.GRAY;
        case FloorballColor.GREEN:
            return FloorballColorHex.GREEN;
        case FloorballColor.ORANGE:
            return FloorballColorHex.ORANGE;
        case FloorballColor.RASPBERRY:
            return FloorballColorHex.RASPBERRY;
        case FloorballColor.WHITE:
            return FloorballColorHex.WHITE;
        case FloorballColor.YELLOW:
            return FloorballColorHex.YELLOW;
        default:
            return FloorballColorHex.WHITE;
    }
}

export function getFloorballColorName(color : string) : FloorballColor {
    switch(color) {
        case FloorballColorHex.BLACK:
            return FloorballColor.BLACK;
        case FloorballColorHex.BLACKBERRY:
            return FloorballColor.BLACKBERRY;
        case FloorballColorHex.BLUE:
            return FloorballColor.BLUE;
        case FloorballColorHex.GRAY:
            return FloorballColor.GRAY;
        case FloorballColorHex.GREEN:
            return FloorballColor.GREEN;
        case FloorballColorHex.ORANGE:
            return FloorballColor.ORANGE;
        case FloorballColorHex.RASPBERRY:
            return FloorballColor.RASPBERRY;
        case FloorballColorHex.WHITE:
            return FloorballColor.WHITE;
        case FloorballColorHex.YELLOW:
            return FloorballColor.YELLOW;
        default:
            return FloorballColor.EMPTY_RED;
    }
}

export default getFloorballColorHex;