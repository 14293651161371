import * as z from 'zod';

const StringToNumber = z
    .string()
    .nonempty()
    .transform((val) => parseInt(val, 10));

const Protocol = z.string().nonempty();

const Port = z.number().positive().min(0).max(65535);

const StringToPort = StringToNumber.refine(
    (val) => Port.safeParse(val).success
);

const Host = z.string().nonempty();

export const EnvFileDeclaration = z.object({
    REACT_APP_BACKEND_PROTOCOL: Protocol,
    REACT_APP_BACKEND_HOST: Host,
    REACT_APP_BACKEND_PORT: StringToPort,
    // FRONTEND_PROTOCOL: Protocol,
    REACT_APP_FRONTEND_HOST: Host,
    REACT_APP_FRONTEND_PORT: StringToPort,
});

export type EnvFileDeclarationType = z.infer<typeof EnvFileDeclaration>;
