import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import {
  getConfigurationLeftColor,
  getConfigurationRightColor,
} from "../../redux/reducers";
import "./Configurator.scss";
import { Image } from "semantic-ui-react";

function Configurator({ leftColor, rightColor }: PropsFromRedux) {
  const { t } = useTranslation();

  return (
    <>
      <div className="logo-container">
        <Image className="logo" src="/img/logo.png"></Image>
        <Image className="ost-logo" src="/img/ost_logo.png"></Image>
      </div>
      <div id="configurator">
        <h1>{t("pages-configurator-title")}</h1>
        <ColorPicker />
      </div>
      <div className="link-container">
        <p>
          Entwickelt von Informatik-Studierenden der Ostschweizer Fachhochschule{" "}
          <a href="https://www.ost.ch"> www.ost.ch</a>
        </p>
      </div>
    </>
  );
}

// REDUX DEFINITIONS
type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(
  (state: any) => ({
    leftColor: getConfigurationLeftColor(state),
    rightColor: getConfigurationRightColor(state),
  }),
  {}
);

export default connector(Configurator);
