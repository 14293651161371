/* eslint-disable no-console */
import {
    EnvFileDeclaration,
    EnvFileDeclarationType,
} from '../declarations/envFile.declaration';

export default class EnvVariableHelper {
    static VARIABLES: EnvFileDeclarationType;

    static loadVariables(): void {
        // @ts-ignore
        const result = EnvFileDeclaration.safeParse({ ...process.env, ...window.env });

        if (!result.success) {
            result.error.issues.forEach((issue) => {
                const variableName = issue.path[0].toString();
                console.log(
                    `Environment variable ${variableName} is invalid or missing.`
                );
            });

            console.log(
                `Please update the .env file. The program is terminated.`
            );
            return;
        }

        this.VARIABLES = result.data;
    }
}
EnvVariableHelper.loadVariables();
